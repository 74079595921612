import {
    default as origUseAxios,
    Options,
    ResponseValues,
    RefetchOptions,
} from "axios-hooks";
import { AxiosRequestConfig } from "axios";

export function useAxios<T = any>(
    config: AxiosRequestConfig | string,
    options?: Options & { defaultData: T }
): [
    ResponseValues<T>,
    (config?: AxiosRequestConfig, options?: RefetchOptions) => void
] {
    if (typeof window === "undefined") {
        return [{
            data: options.defaultData,
            loading: false,
        }, () => {}]
    }
    return origUseAxios(config, options);
}
